.style_cellIcon__2HmZq {
  opacity: 0.5;
  color: #9e9e9e;
}
.style_highlightedCellIcon__2ES5d {
  opacity: 0.5;
  color: #9e9e9e;
}
.style_highlightedCellIcon__2ES5d:hover {
  opacity: 1;
}
.style_iconSizing__10YkF {
  height: 1.5rem;
  width: 1.5rem;
}
.style_canClick__3eo4_ {
  cursor: pointer;
}
.style_iconContainer__3lDV0 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
}
