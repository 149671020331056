.ProjectedTeamsList_root__19kXO {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.ProjectedTeamsList_detail__lSCD3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 1rem;
}
.ProjectedTeamsList_icon__2qN91 {
  width: 32px;
  height: 32px;
  fill: #333333 !important;
}
.ProjectedTeamsList_externalGroups__1o_J2 {
  color: #333333;
}
